/**
** JAVASCRIPTS
** Name: Back to top
********************************************************************************/

(function() {
  'use strict';

  if(document.querySelector('.js-back-to-top')) {


    /**
    ** Variables
    ****************************************/

    const $button = document.querySelector('.js-back-to-top');


    /**
    ** ...
    ****************************************/

    window.addEventListener('scroll', ()=>{
      if($button.classList.contains('is-hidden') && window.scrollY >= window.innerHeight) {
        $button.classList.remove('is-hidden');
      }
      if(window.scrollY < window.innerHeight && !$button.classList.contains('is-hidden')) {
        $button.classList.add('is-hidden');
      }
    });


  }



})();
